import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import UrbanAdventures from "../../components/ArtStyle/UrbanAdventures"

const UrbanAdventuresPage = () => (
  <Layout>
    <Seo title="Urban Adventures" />
    <UrbanAdventures />
  </Layout>
)

export default UrbanAdventuresPage
