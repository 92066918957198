import React, { Component, useState } from "react"
import Lightbox from "react-image-lightbox"
import images from './images';
import * as styles from './styles.module.scss';

// TODO Add proper description
const UrbanAdventures = () => {
  const { collection } = images
  const initialState = {
    photoIndex: 0,
    isOpen: false,
  };

  const [values, setValues]= useState(initialState);
  const setLightBoxIndex = (index) => {
    setValues({
      ...values,
      selectedIndex: index,
      photoIndex: index,
      isOpen: true,
    });
  };

  return (
    <div className={styles.urbanAdventures}>
      <div className={styles.headerContainer}>
        <h1>Urban Adventures</h1>
        <p>
          Fine art prints presented in colorful collages are meant to reflect a modern perspective on
          American cities through striking colors and unusual color blends. The photographic combination
          of historical places and new buildings invokes the desire to explore charming places. Art
          commissions are available if you are interested in receiving the same take on your favorite city.
        </p>
      </div>
      <div className={styles.urbanAdventuresGallery}>
        <div>
          <div className={styles.urbanAdventuresRow}>
            <div><img alt="gallery image" src={collection[0].source} onClick={() => setLightBoxIndex(0)}/></div>
            <div><img alt="gallery image" src={collection[1].source} onClick={() => setLightBoxIndex(1)}/></div>
            <div><img alt="gallery image" src={collection[2].source} onClick={() => setLightBoxIndex(2)}/></div>
          </div>
          <div className={styles.urbanAdventuresRow}>
            <div><img alt="gallery image" src={collection[3].source} onClick={() => setLightBoxIndex(3)}/></div>
            <div><img alt="gallery image" src={collection[4].source} onClick={() => setLightBoxIndex(4)}/></div>
            <div><img alt="gallery image" src={collection[5].source} onClick={() => setLightBoxIndex(5)}/></div>
          </div>
          <div className={styles.urbanAdventuresRow}>
            <div><img alt="gallery image" src={collection[6].source} onClick={() => setLightBoxIndex(6)}/></div>
            <div><img alt="gallery image" src={collection[7].source} onClick={() => setLightBoxIndex(7)}/></div>
            <div><img alt="gallery image" src={collection[8].source} onClick={() => setLightBoxIndex(8)}/></div>
          </div>
          <div className={styles.urbanAdventuresRow}>
            <div><img alt="gallery image" src={collection[9].source} onClick={() => setLightBoxIndex(9)}/></div>
            <div><img alt="gallery image" src={collection[10].source} onClick={() => setLightBoxIndex(10)}/></div>
            <div><img alt="gallery image" src={collection[11].source} onClick={() => setLightBoxIndex(11)}/></div>
          </div>
          <div className={styles.urbanAdventuresRow}>
            <div><img alt="gallery image" src={collection[12].source} onClick={() => setLightBoxIndex(12)}/></div>
            <div><img alt="gallery image" src={collection[13].source} onClick={() => setLightBoxIndex(13)}/></div>
            <div><img alt="gallery image" src={collection[14].source} onClick={() => setLightBoxIndex(14)}/></div>
          </div>
          <div className={styles.urbanAdventuresRow}>
            <div><img alt="gallery image" src={collection[15].source} onClick={() => setLightBoxIndex(15)}/></div>
            <div><img alt="gallery image" src={collection[16].source} onClick={() => setLightBoxIndex(16)}/></div>
          </div>
        </div>
      </div>
      <div className={styles.urbanAdventuresGallery}>
        { values.isOpen && (
          <Lightbox
            imageTitle={collection[values.photoIndex].name}
            mainSrc={collection[values.photoIndex].source}
            nextSrc={collection[(values.photoIndex + 1) % collection.length].source}
            prevSrc={collection[(values.photoIndex + collection.length - 1) % collection.length].source}
            onCloseRequest={() => setValues({...values, isOpen: false})}
            onMovePrevRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + collection.length - 1) % collection.length,
              })
            }
            onMoveNextRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + 1) % collection.length,
              })
            }
          />
        )}
      </div>
      {/*<Gallery*/}
      {/*  className={styles.urbanAdventuresGallery}*/}
      {/*  galleryType="urbanAdventures"*/}
      {/*  images={images}*/}
      {/*  closeModalDispatch={{type: HIDE_MODAL}}*/}
      {/*  setImages={setGalleryImages}*/}
      {/*  nextImage={nextImage}*/}
      {/*  previousImage={previousImage}>*/}
      {/*  <div className={styles.urbanAdventuresRow}>*/}
      {/*    <Image imageData={images.collection[0]}/>*/}
      {/*    <Image imageData={images.collection[1]}/>*/}
      {/*    <Image imageData={images.collection[2]}/>*/}
      {/*  </div>*/}
      {/*  <div className={styles.urbanAdventuresRow}>*/}
      {/*    <Image imageData={images.collection[3]}/>*/}
      {/*    <Image imageData={images.collection[4]}/>*/}
      {/*    <Image imageData={images.collection[5]}/>*/}
      {/*  </div>*/}
      {/*  <div className={styles.urbanAdventuresRow}>*/}
      {/*    <Image imageData={images.collection[6]}/>*/}
      {/*    <Image imageData={images.collection[7]}/>*/}
      {/*    <Image imageData={images.collection[8]}/>*/}
      {/*  </div>*/}
      {/*  <div className={styles.urbanAdventuresRow}>*/}
      {/*    <Image imageData={images.collection[9]}/>*/}
      {/*    <Image imageData={images.collection[10]}/>*/}
      {/*    <Image imageData={images.collection[11]}/>*/}
      {/*  </div>*/}
      {/*  <div className={styles.urbanAdventuresRow}>*/}
      {/*    <Image imageData={images.collection[12]}/>*/}
      {/*    <Image imageData={images.collection[13]}/>*/}
      {/*    <Image imageData={images.collection[14]}/>*/}
      {/*  </div>*/}
      {/*  <div className={styles.urbanAdventuresRow}>*/}
      {/*    <Image imageData={images.collection[15]}/>*/}
      {/*    <Image imageData={images.collection[16]}/>*/}
      {/*  </div>*/}
      {/*</Gallery>*/}
    </div>
  );
}

export default UrbanAdventures;
