import Image1 from './images/zaru_collages_v6-6.png'
import Image2 from './images/zaru_collages_v6-14.png'
import Image3 from './images/zaru_collages_v6-1.png'
import Image4 from './images/zaru_collages_v6-11.png'
import Image5 from './images/zaru_collages_v6-9.png'
import Image6 from './images/zaru_collages_v6-12.png'
import Image7 from './images/zaru_collages_v6-18.png'
import Image8 from './images/zaru_collages_v6-17.png'
import Image9 from './images/zaru_collages_v6-10.png'
import Image10 from './images/zaru_collages_v6-15.png'
import Image11 from './images/zaru_collages_v6-16.png'
import Image12 from './images/zaru_collages_v6-2.png'
import Image13 from './images/zaru_collages_v6-3.png'
import Image14 from './images/zaru_collages_v6-4.png'
import Image15 from './images/zaru_collages_v6-13.png'
import Image16 from './images/zaru_collages_v6-8.png'
import Image17 from './images/zaru_collages_v6-5.png'

export default {
  collection: [
    {
      index: 0,
      name: 'Ambiance of Passion',
      source: Image1,
    },
    {
      index: 1,
      name: 'Moving Serenity',
      source: Image2,
    },
    {
      index: 2,
      name: 'Shining Flavor',
      source: Image3,
    },
    {
      index: 3,
      name: "Life's Embrace",
      source: Image4,
    },
    {
      index: 4,
      name: 'Charisma in Harmony',
      source: Image5,
    },
    {
      index: 5,
      name: 'Treasuring Wisdom',
      source: Image6,
    },
    {
      index: 6,
      name: 'Reliable Force',
      source: Image7,
    },
    {
      index: 7,
      name: 'A Virtuoso of Warmth',
      source: Image8,
    },
    {
      index: 8,
      name: 'B...Acceptance...W',
      source: Image9,
    },
    {
      index: 9,
      name: 'Yin Always Needs Yang',
      source: Image10,
    },
    {
      index: 10,
      name: 'The Orchestra of Quietness',
      source: Image11,
    },
    {
      index: 11,
      name: 'The Orchestra of Quietness',
      source: Image12,
    },
    {
      index: 12,
      name: 'A Sip of Adventure',
      source: Image13,
    },
    {
      index: 13,
      name: 'Subtleness in Transit',
      source: Image14,
    },
    {
      index: 14,
      name: 'Improvisation in Actions',
      source: Image15,
    },
    {
      index: 15,
      name: 'An Emotional Story',
      source: Image16,
    },
    {
      index: 16,
      name: 'Provoking Freestyle',
      source: Image17,
    },
  ],
};
